import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { mapActions } from 'vuex';

// prints
import DrawerOpen from '../prints/draweropen';
import PrintReceipt from '../prints/receipt';
import PrintTable from '../prints/table';

// components
import Item from '../components/Item';
import Transaction from '../components/Transaction';
import Searchbar from '../components/Searchbar';


import S1F2 from '../prints/s1f2';
import md5 from 'js-md5';

import moment from 'moment';

// modals
import ModalSubtotal from '../components/ModalSubtotal';
// import transaction from "../store/transaction";

// todo: make global available
const cache = setupCache({
    maxAge: 15 * 60 * 1000,
    invalidate: async (config, request) => {
        if (request.clearCacheEntry) {
            await config.store.removeItem(config.uuid);
        }
    },
});
const axiosCache = axios.create({
    adapter: cache.adapter,
});

export default {
    data () {
        return {
            selected_group: {},
            selected_group_parent: {},
            selected_item: null,
            groups: [],
            items: {},
            paymenttype: null,
            barcodescanner_listener: true,
            ignoreGetItemsFunction: false,
        };
    },
    mounted () {
        // this.getTerminal();
        if(!this.ignoreGetItemsFunction) {
            this.getItems();
        }
    },
    components: {
        Item,
        Transaction,
        Searchbar,
        ModalSubtotal,
    },
    methods: {
        ...mapActions({
            transactionClear: 'transaction/clear',
            addQuantity: 'transaction/addQuantity',
            subQuantity: 'transaction/subQuantity',
            lineSelectedDelete: 'transaction/lineSelectedDelete',
            lineSelectedRetour: 'transaction/lineSelectedRetour',
            lineDelete: 'transaction/lineDelete',
          // lineSelectedDiscount: 'transaction/lineSelectedDiscount',
        }),

        lineSelectedDiscount () {

            if (this.transaction.lines.filter(line => {
                return line.selected == true;
            }).length == 0) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldige actie",
                    text: "Geen regel geselecteerd",
                }, 1000);

                return;
            }

            if (!this.quantity || this.quantity <= 0 || this.quantity > 100) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldige actie",
                    text: "Ongeldig percentage ingevoerd",
                }, 1000);
                this.clearInput();
                return;
            }

            this.$store.dispatch('transaction/lineSelectedDiscount', this.quantity);
            this.clearInput();
            // if (quantity <= 0) {
            //     this.$notify({ group: "notifications", type: 'error', title: "Ongeldige actie", text: "Percentage moet groter zijn dan 0" }, 1000);
            // }

        },

        setBarcodeScannerListener (status) {
            this.barcodescanner_listener = status;
        },

        // open the drawer with a starprinter
        drawerOpen () {
            new DrawerOpen().print();
        },

        async gotoTransaction (paymenttype_id) {
            // check if the transactions contains lines

            if (this.transaction.lines == 0 && this.transaction.table_amount == null) {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Ongeldige actie",
                    text: "Geen artikelen aangeslagen",
                }, 1000);
                return;
            }

            // is clerk required
            if (this.terminal.clerk_required && !this.transaction.clerk_id) {
                this.$router.push({ name: 'clerk.login' });
                return;
            }

            // is table required
            if (this.terminal.table_required && !this.transaction.table_id) {
                this.$router.push({ name: 'pos.tables' });
                return;
            }

            // is subtotal required
            if (this.terminal.subtotal_required && !this.transaction.subtotal_id && this.$refs.modalSubtotal) {
                this.$refs.modalSubtotal.open();
                return;
            }

            if (this.transaction.lines.some(line => line.hourly_rate_item == true)) {
                const hourly_rate_index = this.transaction.lines.findIndex(line => line.hourly_rate_item == true);

                if (hourly_rate_index !== -1) {

                    this.transaction.lines.splice(hourly_rate_index, 1);

                    this.$store.dispatch('transaction/setLines', this.transaction.lines);
                }
            }

            if (this.transaction.table_hourly_rate_item_id && this.transaction.table_activated_at) {
                const response = await axios.get('/tables/' + this.transaction.table_id);

                if (response.status == 200) {
                    const table = response.data.data;

                    if (table.attributes.activated_at) {
                        const startDate = new Date(table.timestamp);
                        const endDate   = new Date(table.attributes.activated_at);
                        const secondsDifference = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;
                        const hoursDifference = secondsDifference / 3600;

                        const response = await axios.post('timeslots', {
                            location_id: table.relationships.location.id,
                            date: moment().format('YYYY-MM-DD'),
                            function: 'reservation',
                            area_id: table.attributes.area_id,
                        });

                        if (response.data) {
                            const timeslot = response.data;

                            let item = timeslot.attributes.reservation_item;

                            console.log(item);

                            const price = this.calculateTotalPrice(item.attributes.price, hoursDifference, timeslot);

                            let data = {
                                parent_id: item.line_parent_id ? item.line_parent_id : null,
                                item_id: item.id,
                                item_parent_id: item.parent_id,
                                addon: false,
                                composed_child: item.composed_child ? item.composed_child : false,
                                barcode: item.attributes.barcode,
                                description: item.attributes.description + (item.variant ? ' - ' + item.variant.attributes.label : ''),
                                // thumbnail:  item.attributes.thumbnail_url,
                                taxrate: item.attributes.taxrate,
                                rate: price,
                                hourly_rate_item: true,
                                kitchen_groceries: item.kitchen_groceries,

                                variant_id: item.variant ? item.variant.id : null,
                                variant_label: item.variant ? item.variant.attributes.label : null,

                                // addons?!
                                addons: item.addons,

                                card: item.attributes.card ? item.attributes.card : null,
                                cardnumber: this.cardnumber,

                                notes: item.notes,
                            };

                            data.id = md5(JSON.stringify(data));
                            data.quantity = 1;


                            this.$store.dispatch('transaction/addItem', data);
                        }
                    }
                }
            }

            if (this.transaction.table_id) {
                const response = await axios.get('/tables/' + this.transaction.table_id + '/open-transaction');

                if (response.status == 200) {
                    if (response.data.data.has_open_transaction) {
                        return this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: 'Tafel bezet',
                            text: "Tafel heeft een openstaande transactie op een andere terminal",
                        }, 2000);
                    }
                }
            }

            // are the items available
            await axios.post('/items/check-availability', {
                items: this.transaction.lines
            }).then(response => {
                    if (response.data.result === 'failed') {

                        // reload the items
                        this.getItems(true);

                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Niet op voorraad",
                            text: "Artikel(en) niet op voorrraad: " + response.data.message,
                        }, 2000);
                        return;
                    }
                    // redirect to transaction page
                    this.$router.push({ name: 'transaction', query: { paymenttype_id: paymenttype_id } });
                })
                .catch(() => {

                    // reload the items
                    this.getItems(true);

                    // show notification
                    this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: '' }, 2000);
                    return;
                });
        },

        sortItemsByQuantity (items) {
            return items.sort((a, b) => a.quantity - b.quantity);
        },

        calculateTotalPrice (price, hours, timeslot) {
            // if no staffelprijzen return price * hours
            if (timeslot.attributes.reservation_items_multiple_timeslots.length == 0) {
                return price * hours;
            }

            //sort items by quantity
            const items = this.sortItemsByQuantity(timeslot.attributes.reservation_items_multiple_timeslots);

            // check which quantity is the last 'reached' one, comparing hours with quantity.
            // so quanity [1,3,5,7] with hours 6 will return 5, the last one passed
            let lastQuantityItem = null;
            items.forEach(function(item) {
                if (hours >= item.quantity) {
                    lastQuantityItem = item;
                }
            });

            //if none yet reached just return price * hours
            if (lastQuantityItem == null) {
                return price * hours;
            }

            return lastQuantityItem.item.attributes.price;
        },

        printReceipt (transaction_id) {
            if (transaction_id) {
                axios.get('/transactions/' + transaction_id)

                    .then(response => {
                        console.log('here', this.terminal.printer_mode)
                        if (this.terminal.printer_mode.includes('local_S1F2')) {
                            this.printS1F2(response.data.data, 'transaction');
                        } else {
                            new PrintReceipt().print(response.data.data);
                        }
                    })
                    .catch(() => {
                    });
            } else {
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Geen transactie",
                    text: "Kan geen bon printen, geen transactionID",
                }, 2000);
            }
        },

        async printReceiptTable () {
            let table_id = this.transaction.table_id;


            // if open transaction, close the table before printing the receipt.
            if (this.transaction.items > 0) {
                await axios.post('/tables/' + this.transaction.table_id + '/close', this.transaction).then(() => {

                    this.$notify({ group: "notifications", type: 'success', title: 'Tafel ' + this.transaction.table_name, text: "Tafel gesloten" }, 2000);

                }).catch(() => {

                    this.$notify({ group: "notifications", type: 'error', title: "Mislukt", text: "Sluiten van de tafel" }, 2000);
                }).finally(() => {
                    this.busy = false;
                })
            }

            if (table_id) {
                await axios.get('/tables/' + table_id, {
                    consolidated: true,
                }).then(response => {
                    if (this.terminal.printer_mode.includes('local_S1F2')) {
                        this.printS1F2(response.data.data, 'table', this.terminal);
                    } else {
                        const data = {
                            ...response?.data?.data ?? {},
                            attributes: {
                                ...(response.data?.data?.attributes ?? {}),
                            }
                        }

                        // Fix for "attributes.clerk_id" is not set
                        if (!data?.attributes?.clerk_id && this.clerk?.id) {
                            data.attributes.clerk_id = this.clerk.id;
                        }

                        new PrintTable().print(data);
                    }

                }).catch(() => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                });

            } else {
                this.$notify({
                    group: "notifications",
                    type: 'warning',
                    title: "Geen tafel geselecteerd",
                    text: "Kan geen bon printen",
                }, 2000);
            }

            //
            if (this.transaction.items > 0) {
                this.$store.dispatch('transaction/clear')
            }
        },

        async printS1F2 (transactionResponse, type) {
            // Fix for "attributes.clerk_id" is not set when preview.
            if (!transactionResponse?.attributes?.clerk_id && this.clerk?.id) {
                transactionResponse.attributes.clerk_id = this.clerk.id;
            }

            // let env = this.terminal.printer_mode.replace('local_S1F2_', '');
            let pin_terminal_id = this.terminal.pin_terminal_id;

            let receiptContent = await new S1F2().generateReceiptContentS1F2(transactionResponse, type, this.terminal);

            console.log(receiptContent, pin_terminal_id);

            await axios.post('/terminal/printS1F2', {
                content: receiptContent,
                // environment: env,
                pin_terminal_id: pin_terminal_id,
            }).then(response => {

                console.log('success', response);

            }).catch(error => {
                console.error('error', error);
            });
        },

        tablesOpen () {
            // if (this.transaction.cashfunction_included) {
            //     this.$notify({ group: "notifications", type: 'error', title: "Ongeldige actie", text: "Kan geen kasfunctie boeken op tafel" }, 2000);
            //     return
            // }
            this.$router.push({ name: 'pos.tables' });
        },

        async search (searchquery) {
            if (searchquery === false) {
                this.selectGroup(this.groups[0]);

                return;
            }

            let items = [];
            this.groups.forEach(function (group) {
                group.items.forEach(function (item) {
                    if (item.attributes.description.toUpperCase().includes(searchquery.toUpperCase())) {
                        items.push(item);
                    }
                });
            });

            if (searchquery.length > 2) {
                // search for not visbible items
                const response = await axios.post('/items/search', {
                    searchquery: searchquery,
                    visible: false,
                });

                if (response && response.data.data.length > 0) {
                    items = items.concat(response.data.data);
                }
            }

            this.selected_group = {
                name: 'Zoeken',
                items: items,
            };
        },

        async getTerminal () {
            console.log('terminal 1')
            // get items (cached)
            await axiosCache({
                url: '/terminal',
                method: 'get',
            })
                .then(response => {
                    // axios.get('/terminal').then(response => {
                    this.$store.dispatch('terminal/terminal', response.data.data);
                    this.$store.dispatch('location/location', response.data.data.relationships.location);
                    this.$store.dispatch('printer/printer', response.data.data.relationships.printer);

                }).catch(error => {
                    if(error.response.status === 401){
                        return;
                    }else{
                        this.$notify({
                            group: "notifications",
                            type: 'error',
                            title: "Fout opgetreden",
                            text: "Oeps er ging iets fout..",
                        }, 2000);
                        console.error(error);
                    }
                });
        },

        getPerferredPaymenttype () {
            // get preferred paymenttype (cached)
            axiosCache({
                url: '/paymenttypes/preferred',
                method: 'get',
            }).then(response => {
                // axios.get('/paymenttypes/preferred').then(response => {
                this.paymenttype = response.data.data;
            }).catch(error => {
                console.error(error);
            });
        },

        clearTransaction () {
            this.$router.push({ name: 'landing' });
            this.$store.dispatch('transaction/clear');
        },

        async getItems (clearCache = false) {

            if (clearCache) {
                localStorage.removeItem('masterdata.items');
            }
            let dataItems = JSON.parse(localStorage.getItem('masterdata.items'));

            if (!dataItems) {
                try {
                    const response = await axios({
                        url: '/items',
                        method: 'get',
                    });

                    dataItems = response.data.data;
                    localStorage.setItem('masterdata.items', JSON.stringify(dataItems));
                } catch (error) {
                    localStorage.removeItem('masterdata.items');
                    console.error(error);

                    return this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                }
            }
            this.groups = dataItems;

            if (this.groups && this.groups.length > 0) {
                this.selectGroup(this.groups[0]);
            }
        },

        selectGroup (group) {
            this.selected_group = group;
        },

        selectGroupById (groups, group_id) {
            if (group_id == null) {
                return;
            }

            for (let index in groups) {
                if (groups[index].id == group_id) {
                    this.selected_group = groups[index];
                } else if (groups[index].children) {
                    this.selectGroupById(groups[index].children, group_id);
                }
            }
        },

        setDefaultTable () {
            if (this.terminal.default_table_id && !this.transaction.table_id && !this.transaction.cashfunction_included) {
                axios.get('/tables/' + this.terminal.default_table_id).then(response => {
                    this.$store.dispatch('transaction/setTable', response.data.data);
                }).catch(error => {
                    this.$notify({
                        group: "notifications",
                        type: 'error',
                        title: "Fout opgetreden",
                        text: "Oeps er ging iets fout..",
                    }, 2000);
                    console.error(error);
                });
            }
        },

        pos_visible (items) {
            if (Array.isArray(items)) {
                return items.filter(item => item.attributes.pos_visible === true);
            }

            return [];
        },
    },
    computed: {
        clerk() {
            return this.$store.getters['transaction/clerk'];
        },
        transaction () {
            return this.$store.getters['transaction/transaction'];
        },
        terminal () {
            return this.$store.getters['terminal/terminal'];
        },
        itemscanner () {
            return this.$store.getters['settings/itemscanner'];
        },
    },
};
